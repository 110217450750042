import React from "react";

import { Typography } from "@mui/material";
import './Footer.css'



function Footer() {
    return (
        <footer className="App-footer">
        <Typography variant="body2">&copy; 2024 Austin Prime Property Care. All rights reserved.</Typography>
      </footer>
    )
};

export default Footer
