import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import jsPDF from 'jspdf';
import './QuoteGenerator.css';
import { useNavigate } from 'react-router-dom';

function QuoteGenerator() {
  const [invoice, setInvoice] = useState({
    name: '',
    address: '',
  });
  const [isEditingInvoice, setIsEditingInvoice] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [editingTaskIndex, setEditingTaskIndex] = useState(null);
  const [totalCost, setTotalCost] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountedTotal, setDiscountedTotal] = useState(0);

  const navigate = useNavigate();

  const handleInvoiceChange = (e) => {
    const { name, value } = e.target;
    setInvoice({ ...invoice, [name]: value });
  };

  const addTask = () => {
    setTasks([
      ...tasks,
      { surfaceType: '', sqft: '', grimeLevel: '', time: '', cost: 0 },
    ]);
    setEditingTaskIndex(tasks.length); // Automatically open editing mode for the new task
  };

  const handleTaskChange = (index, e) => {
    const { name, value } = e.target;
    const updatedTasks = tasks.map((task, i) =>
      i === index
        ? { ...task, [name]: name === 'sqft' || name === 'time' ? Math.max(0, value) : value }
        : task
    );
    setTasks(updatedTasks);
  };

  const calculateTaskCost = (task) => {
    const { sqft, grimeLevel, time } = task;
    const baseRate = 0.1; // $ per sqft
    const grimeMultiplier = grimeLevel ? parseFloat(grimeLevel) : 1; // Grime factor
    const timeCost = time ? parseFloat(time) * 60 : 0; // $60/hour
    return parseFloat(sqft || 0) * baseRate * grimeMultiplier + timeCost;
  };

  const finalizeTask = (index) => {
    const updatedTasks = tasks.map((task, i) =>
      i === index ? { ...task, cost: calculateTaskCost(task) } : task
    );
    setTasks(updatedTasks);
    setEditingTaskIndex(null);
    calculateTotalCost(updatedTasks); // Update the total dynamically
  };

  const calculateTotalCost = (updatedTasks = tasks) => {
    const total = updatedTasks.reduce((acc, task) => acc + task.cost, 0);
    setTotalCost(total.toFixed(2));
    setDiscountedTotal(total.toFixed(2)); // Initialize discountedTotal to the original total
  };

  const applyDiscount = () => {
    const discounted = totalCost - (totalCost * discount) / 100;
    setDiscountedTotal(discounted.toFixed(2));
  };

  const deleteTask = (index) => {
    if (window.confirm('Are you sure you want to delete this task?')) {
      const updatedTasks = tasks.filter((_, i) => i !== index);
      setTasks(updatedTasks);
      calculateTotalCost(updatedTasks); // Update the total dynamically
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text('Invoice Breakdown', 10, 10);

    doc.setFontSize(12);
    doc.text(`Name: ${invoice.name}`, 10, 20);
    doc.text(`Address: ${invoice.address}`, 10, 30);

    doc.text('Tasks:', 10, 40);

    let y = 50;
    tasks.forEach((task, index) => {
      doc.text(
        `Task ${index + 1}: ${task.surfaceType}, ${task.sqft} sqft, Grime Level ${task.grimeLevel}, ${task.time} hrs, $${task.cost.toFixed(2)}`,
        10,
        y
      );
      y += 10;
    });

    doc.text(`Total Before Discount: $${totalCost}`, 10, y + 10);
    doc.text(`Discount: ${discount}%`, 10, y + 20);
    doc.text(`Total After Discount: $${discountedTotal}`, 10, y + 30);

    doc.save('invoice.pdf');
  };

  return (
    <div className="QuoteGenerator">
      <Container>
        <Typography variant="h4" gutterBottom align="center">
          Quote Generator
        </Typography>
        <Card>
          <CardContent>
            {/* Invoice Details */}
            {isEditingInvoice ? (
              <>
                <Typography variant="h6">Invoice Details</Typography>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={invoice.name}
                  onChange={handleInvoiceChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Address"
                  name="address"
                  value={invoice.address}
                  onChange={handleInvoiceChange}
                  margin="normal"
                  required
                />
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsEditingInvoice(false)}
                  >
                    Save Invoice Details
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Typography variant="h6">
                  {invoice.name} - {invoice.address}
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => setIsEditingInvoice(true)}
                    style={{ marginLeft: '10px' }}
                  >
                    Edit
                  </Button>
                </Typography>
              </>
            )}

            {/* Add Task Button */}
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={addTask}
              >
                Add Task
              </Button>
            </Box>

            {/* Task List */}
            {tasks.map((task, index) => (
              <Card key={index} style={{ marginTop: '10px', position: 'relative' }}>
                <CardContent>
                  {editingTaskIndex === index ? (
                    <>
                      <Typography variant="subtitle1">
                        Editing Task {index + 1}
                      </Typography>
                      <TextField
                        fullWidth
                        label="Surface Type"
                        name="surfaceType"
                        value={task.surfaceType}
                        onChange={(e) => handleTaskChange(index, e)}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Square Footage"
                        name="sqft"
                        type="number"
                        value={task.sqft}
                        onChange={(e) => handleTaskChange(index, e)}
                        margin="normal"
                      />
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Grime Level</InputLabel>
                        <Select
                          name="grimeLevel"
                          value={task.grimeLevel}
                          onChange={(e) => handleTaskChange(index, e)}
                        >
                          <MenuItem value={1}>1 - Light</MenuItem>
                          <MenuItem value={2}>2 - Moderate</MenuItem>
                          <MenuItem value={3}>3 - Heavy</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        fullWidth
                        label="Time to Complete (hours)"
                        name="time"
                        type="number"
                        value={task.time}
                        onChange={(e) => handleTaskChange(index, e)}
                        margin="normal"
                      />
                      <Box mt={2}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => finalizeTask(index)}
                        >
                          Save Task
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Typography variant="subtitle1">
                        Task {index + 1}: {task.surfaceType} - {task.sqft} sqft - Grime Level {task.grimeLevel} - {task.time} hours
                      </Typography>
                      <Typography variant="body2">
                        Cost: ${task.cost.toFixed(2)}
                      </Typography>
                      <Box position="absolute" top="16px" right="16px">
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => setEditingTaskIndex(index)}
                          style={{ marginRight: '10px' }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="text"
                          color="error"
                          onClick={() => deleteTask(index)}
                        >
                          Delete
                        </Button>
                      </Box>
                    </>
                  )}
                </CardContent>
              </Card>
            ))}

            {/* Invoice Breakdown */}
            {tasks.length > 0 && (
              <>
                <Typography variant="h6" align="center" style={{ marginTop: '20px' }}>
                  Invoice Breakdown
                </Typography>
                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Task</TableCell>
                        <TableCell>Surface Type</TableCell>
                        <TableCell>Square Footage</TableCell>
                        <TableCell>Grime Level</TableCell>
                        <TableCell>Time (hours)</TableCell>
                        <TableCell>Cost ($)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tasks.map((task, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{task.surfaceType}</TableCell>
                          <TableCell>{task.sqft}</TableCell>
                          <TableCell>{task.grimeLevel}</TableCell>
                          <TableCell>{task.time}</TableCell>
                          <TableCell>${task.cost.toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box mt={2}>
                  <Typography variant="body1">Total Cost Before Discount: ${totalCost}</Typography>
                  <TextField
                    fullWidth
                    label="Discount (%)"
                    type="number"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    margin="normal"
                  />
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={applyDiscount}
                    >
                      Apply Discount
                    </Button>
                  </Box>
                  <Typography variant="body1" style={{ marginTop: '10px' }}>
                    Total Cost After Discount: ${discountedTotal}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Discount Applied: -${(totalCost - discountedTotal).toFixed(2)}
                  </Typography>
                </Box>
                <Box mt={4} display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={generatePDF}
                  >
                    Finalize and Generate PDF
                  </Button>
                </Box>
              </>
            )}
          </CardContent>
        </Card>
        {/* Back to Home Button */}
        <Box display="flex" justifyContent="center" mt={4}>
          <Button variant="contained" color="primary" onClick={() => navigate('/')}>
            Back to Home
          </Button>
        </Box>
      </Container>
    </div>
  );
}

export default QuoteGenerator;
