// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import QuoteFormPage from './components/QuoteForm/QuoteFormPage.js';
import QuoteGenerator from './components/QuoteGenerator/QuoteGenerator.js';
import Footer from './components/Footer/Footer.js';
import Header from './components/Header/Header.js';

const screenMaxSize = 950;


function App() {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= screenMaxSize);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= screenMaxSize);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Router>
      <Header isMobile={isMobile} />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/quote" element={<QuoteFormPage />} />
          <Route path="/generator" element={<QuoteGenerator />} />
        </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
